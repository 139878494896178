import { style } from 'typestyle'
import { Color, Font } from '../var'
export const FooterStyle = style({
  width: '100%',
  fontFamily: 'Avenir',
  backgroundColor: Color.black,
  color: Color.lightGray,
  $nest: {
    '& .content': {
      //marginTop: '-10px',
      paddingTop:'100px',
      paddingBottom:'0px',
      display: 'grid',
      gridTemplateColumns: 'repeat(4,1fr)',
      $nest: {
        '& .item': {
          fontSize: Font.normal,
          display:'grid',
          $nest: {
            
            '& a': {
              display: 'flex',
              flexDirection: 'column',
              color: Color.lightGray,
              textDecoration: 'none'
            },
            '& img':{
              justifySelf:'center',
              alignSelf:'center',
              width:'80%'
            },
            '& div': {
              paddingBottom: '20px',
              $nest: {
                '& .title': {
                  fontWeight: 'bold',
                }
              }
            },
            '@media screen and (max-width: 1440px)': {
              fontSize: Font.micro,
              fontWeight: '100'
            }
          }
        },
        '& .logo': {
          width: '80%',
        },
        '& .desktop': {
          display: 'flex',
        },
        '& .mobile': {
          display: 'none'
        },
        '@media screen and (max-width: 700px)': {
          gridTemplateColumns: 'repeat(1,1fr)',
          paddingTop: '60px',
          $nest: {
            '& .logo': {
              order: '4',
              width: '50%',
              $nest: {
                '& .desktop': {
                  display: 'none'
                },
                '& .mobile': {
                  display: 'flex'
                },
                '& img': {
                  width: '100%'
                }
              }
            }
          }

        },
      }
    },
    '& .allCopy':{
      display: 'grid',
      gridTemplateColumns: 'repeat(12,1fr)',
      fontSize: Font.micro,
      $nest:{
        '& :nth-child(1)':{
          gridColumn:'1/6',
          color: Color.white,
          $nest: {
            '@media screen and (max-width: 1024px)':{
              gridColumn:'1/7',
            }
          }
        },
        '& :nth-child(2)':{
          gridColumn:'6/8',
          justifySelf:'center',
          color: Color.mediumGray,
          $nest: {
            '@media screen and (max-width: 1024px)':{
              gridColumn:'7/10',
              justifySelf:'initial',
            },
          }
        },
        '& .copyContent': {
          paddingTop: '80px',
          paddingBottom: '50px',
          '& .decorationNone': {
            textDecoration: 'none',
            textDecorationColor: Color.mediumGray,
            color: Color.mediumGray,
          },
          $nest: {
            '@media screen and (max-width: 768px)':{
              paddingTop: '20px',
              paddingBottom: '20px',
              textAlign:'center'
            }
          }
        },
        '@media screen and (max-width: 1440px)':{
          fontSize:Font.min,
          $nest:{
            '@media screen and (max-width: 1280px)':{
              fontSize: Font.mini,
            },
          }
        },
        '@media screen and (max-width: 768px)':{
          display:'flex',
          flexDirection:'column'
        }
    }
    },
    
  }
}) 