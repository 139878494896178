/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
// Style
import { menuStyle } from '../../style/menu/style'
import { container,button } from '../../style/var'
//Context
import {MenuContext} from '../../context/menu'
// lang
import { useTranslation } from 'react-i18next'

const Menu = () => {
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState(false);
  const [icon, setIcon] = useState(false)
  const location = useLocation()
  const changeMenu = () => {
    window.scrollTo(0, 0,)
    setOpen(!open)
    setIcon (!icon)
  }
  const { language, setLanguage, menu } = useContext(MenuContext)

  const handleChangeLang = (lang) => {
    i18n.changeLanguage(lang)
    setLanguage(lang)
  }

  return (
    <div className={menuStyle} >
      <div className={`${container} contMenu`}>
        <div className="logo">
          <Link to='/home' onClick={()=>window.scrollTo(0, 0,)}>
            <img src="./assets/logo-fggt.svg" alt="Logo" className="desktop"/>
            <img src="./assets/Mobile/Logo.svg" alt="Logo" className="mobile"/>
          </Link>
        </div>
        <div className={`navLinks` +(icon?' active':'')} >
          <div className="top">
            <div className="item">
            <a href="https://api.whatsapp.com/send?phone=971523345409"className={`${button} btn`} target="_blank">WhatsApp</a>
            {/* <a href="https://botim.me/download/" target="_blank" className={`${button} btn`}>Botim</a> */}
            </div>
            <div className="idiom">
              <img src="./assets/Icon language.png" alt="icon Idiom"/>
              <span onClick={()=> {handleChangeLang('en')}}>En | </span>
              <span onClick={()=> {handleChangeLang('es')}}>Es | </span>
              <span onClick={()=> {handleChangeLang('fr')}}>Fr</span>
            </div>
          </div>
          <div className={`buttom` +(open?' active':'')}>

           {/*   {menu.map((item,i)=> <Link className={`desktop ${item.viewLinks.find(e=> e===location.pathname.replace('/', '')) ? 'active' : '' }`} to={item.link} key={i} onClick={()=>window.scrollTo(0, 0,)} >{t(item.label)}</Link>)} */}
              <Link
                className={`desktop ${location.pathname === '/home' ? 'active' : '' }`}
                to='/home' onClick={()=>window.scrollTo(0, 0,)}
              >
                {t('menu.home')}
              </Link>
              <Link
                className={`desktop ${location.pathname === '/PreciousMetals' ? 'active' : '' }`}
                to='/PreciousMetals' onClick={()=>window.scrollTo(0, 0,)}
              >
                {t('menu.metals')}
              </Link>
              {language !== 'fr' &&
                <Link
                  className={`desktop ${location.pathname === '/nanoMetals' ? 'active' : '' }`}
                  to='/nanoMetals'
                  onClick={()=>window.scrollTo(0, 0,)}
                >
                  {t('menu.nanoMetals')}
                </Link>
              }
              <Link
                className={`desktop ${location.pathname === '/diamonds' ? 'active' : '' }`}
                to='/diamonds'
                onClick={()=>window.scrollTo(0, 0,)}
              >
                {t('menu.diamonds')}
              </Link>
              <Link
                className={`desktop ${location.pathname === '/preciousGemstones' ? 'active' : '' }`}
                to='/preciousGemstones'
                onClick={()=>window.scrollTo(0, 0,)}
              >
                {t('menu.gemstones')}
              </Link>
              <Link
                className={`desktop ${location.pathname === '/jewerly' ? 'active' : '' }`}
                to='/jewerly'
                onClick={()=>window.scrollTo(0, 0,)}
              >
                {t('menu.jewerly')}
              </Link>
              <Link
                className={`desktop ${location.pathname === '/compliance' ? 'active' : '' }`}
                to='/compliance'
                onClick={()=>window.scrollTo(0, 0,)}
              >
                {t('menu.compliance')}
              </Link>
              <Link
                className={`desktop ${location.pathname === '/logistics' ? 'active' : '' }`}
                to='/logistics'
                onClick={()=>window.scrollTo(0, 0,)}
              >
                {t('menu.logistic')}
              </Link>
              <Link
                className={`desktop ${location.pathname === '/contact' ? 'active' : '' }`}
                to='/contact'
                onClick={()=>window.scrollTo(0, 0,)}
              >
                {t('menu.contact')}
              </Link>
                <div className={(open? ' activeMenu':' inactive')}>
                   <div className="idiom">
                  <span onClick={()=> {handleChangeLang('en')}}>En | </span>
                  <span onClick={()=> {handleChangeLang('es')}}>Es | </span>
                  <span onClick={()=> {handleChangeLang('fr')}}>Fr</span>
                  </div>
                  <div className="options">
                    {/* {menu.map((item,i) => <Link to={item.link} key={i} onClick={()=>changeMenu()} >{t(item.label)}</Link>)} */}
                    <Link to='/home' onClick={()=>changeMenu()} >{t('menu.home')}</Link>
                    <Link to='/PreciousMetals' onClick={()=>changeMenu()} >{t('menu.metals')}</Link>
                    {language !== 'fr' &&
                      <Link to='/nanoMetals' onClick={()=>changeMenu()} >{t('menu.nanoMetals')}</Link>
                    }
                    <Link to='/diamonds' onClick={()=>changeMenu()} >{t('menu.diamonds')}</Link>
                    <Link to='/preciousGemstones' onClick={()=>changeMenu()} >{t('menu.gemstones')}</Link>
                    <Link to='/jewerly' onClick={()=>changeMenu()} >{t('menu.jewerly')}</Link>
                    <Link to='/compliance' onClick={()=>changeMenu()} >{t('menu.compliance')}</Link>
                    <Link to='/logistics' onClick={()=>changeMenu()} >{t('menu.logistic')}</Link>
                    <Link to='/contact' onClick={()=>changeMenu()} >{t('menu.contact')}</Link>
                  </div>
                  <div className="new">
                    <img src="./assets/LogoMenuResponsive.svg" alt="Logo" className="mobile"/>
                  </div>
                </div>
            </div>
          </div>
          <div className='contIcon'>
            <div className={`hamburgerIcon ` + (icon ? ' active' :'') } onClick={() => changeMenu()} >
              <div className="lines line-top icon"></div>
              <div className="lines line-mid icon"></div>
              <div className="lines line-buttom icon"></div>
            </div>
          </div>
      </div>
      <div className={`${container} buttonsResponsive`}>
        <a href="https://api.whatsapp.com/send?phone=97152334540" className={`${button} btnRes btnWhatsapp`} target="_blank">WhatsApp</a>
        <a  href="https://botim.me/download/" target="_blank" className={`${button} btnRes`}>Botim</a>
      </div> 
    </div>
  )
}

export default Menu