import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Banner from "../utils/banner";
import List from "../utils/list";
import TableInfo from "../utils/tableInfo";

import { NanoMetalsStyle } from "../../style/nanoMetals/style";
import { container } from "../../style/var";

import { NanoMetalsContext } from "../../context/nanoMetals/index";
import { MenuContext } from "../../context/menu";

const NanoMetals = () => {
  const { t } = useTranslation();
  const { list } = useContext(NanoMetalsContext);
  const { language } = useContext(MenuContext);

  return language !== "fr" ? (
    <div className={NanoMetalsStyle}>
      <Banner
        img="./assets/Desktop/NanoMetals/banner_1.png"
        imgResponsive="./assets/Mobile/NanoMetals/banner.png"
        title="nanoMetals.title"
      />
      <div>
        <List data={list} />
        <div className={`${container} tableInfo`}>
          <div className="line">
            <svg height="8" width="122">
              <line
                x1="0"
                y1="0"
                x2="122"
                y2="0"
                style={{ stroke: "#333333", strokeWidth: 2 }}
              />
            </svg>
          </div>
          <div className="text">
            <div className="infoTitle">Product Properties</div>
            <TableInfo />
            <p className="disclaimer">{t("nanoMetals.tableDisclaimer")}</p>
            <p className="important_text">
              {t("nanoMetals.important")}
            </p>
            <div className="galeryProducts">
              <div className="galeryTitle">
                {t("nanoMetals.titleLaboratories")}{" "}
              </div>
              <div className="galeryContainers">
                <div className="item">
                  <img src="./assets/Desktop/NanoMetals/logo1.png" alt="Logo"/>
                </div>
                <div className="item">
                  <img src="./assets/Desktop/NanoMetals/logo2.png" alt="Logo"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to="/home" />
  );
};

export default NanoMetals;
