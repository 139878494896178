export const es = {
  menu:{
    home:'Inicio',
    about: 'Quienes Somos',
    metals:'Metales preciosos',
    diamonds: 'Diamantes',
    gemstones:'Piedras Preciosas',
    jewerly:'Joyería',
    compliance:'Cumplimiento',
    contact:'Contáctenos',
    logistic: 'Logística',
    nanoMetals: 'Nano Metales',
  },
  home: {
    banner: 'Metales preciosos, joyas, diamantes y piedras preciosas con<br/>los más altos niveles de normativas y regulaciones internacionales.',
    company:{
      title: 'Nuestra compañía',
      whatDo:{
        title: 'Qué hacemos mejor.',
        text: 'FGGT es una empresa con sede en Dubai, el objetivo de la empresa es maximizar el valor para los accionistas a través del descubrimiento y desarrollo de oportunidades en base a metales preciosos, joyas, diamantes y piedras preciosas en el mercado local e internacional.<br/><br/>Empleamos los más altos niveles de regulación por los estándares internacionales de comercio, manteniendo siempre altos estándares de integridad, excelencia y cumplimiento. En FGGT estamos comprometidos con los procedimientos y regulaciones que cubren específicamente el abastecimiento responsable de metales preciosos, diamantes y piedras preciosas libres de conflictos y siempre haciendo la debida diligencia requerida dentro de la cadena de suministro para garantizar el cumplimiento de las políticas y procedimientos locales e internacionales para una buena entrega y suministro.<br/><br/>Estamos comprometidos con nuestros planes de expansión, posición de liderazgo en el mercado local e internacional y nuestros objetivos de excelencia empresarial, que lograremos al continuar brindando un servicio al cliente sin igual y los más altos estándares de calidad en todos los aspectos de nuestras operaciones.'
      },
      commitment:{
        title:'Nuestro compromiso.',
        text:'FGGT es una empresa de personas; esto significa que ponemos a nuestros proveedores, socios, clientes y empleados en el centro de todo lo que hacemos. Nuestra cultura celebra la diversidad y la inclusión, al tiempo que se une en torno a una misión común y un privilegio distintivo de ayudar a nuestra gente. Proporcionamos estándares y compromisos únicos a través de nuestros socios de confianza.<br/><br/>Nos esforzamos por asegurarnos de que nuestros profesionales, las oportunidades de desarrollo personal y social desaten todo su potencial y tengan las mismas oportunidades de avance, en FGGT, fomentamos un entorno inclusivo hacia nuestros asociados de la cadena de suministro y nuestros empleados.'
      },
      values:{
        title:'Valores.',
        text:'Nuestra gente es lo primero. Nos apoyamos mutuamente, aceptamos las diferencias, celebramos la singularidad, fomentamos el desarrollo y recompensamos el desempeño.<b> Transformamos nuestro futuro con valentía y visión trabajando en equipo.</b><br/><br/>Somos curiosos e innovadores; somos ágiles en lo que hacemos y valientes, siempre comprometidos con ganar al enfocarnos en las prioridades que marcan la diferencia. Cumplimos nuestros compromisos porque somos personalmente responsables, aprendemos de los errores y nos esforzamos por lograr una mejora continua. Somos dignos de confianza con la más alta integridad.<br/><br/>Escuchamos y siempre buscamos la verdad juntos, y la contamos como es, incluso cuando es difícil. La comunicación es siempre abierta y clara y nos esforzamos por simplificar e impulsar la colaboración con honestidad y respeto. Nos complace ganarnos la confianza de nuestros proveedores y socios, desarrollar relaciones duraderas y brindar la mejor calidad, servicio y valor.'
      },
      allies: {
        title: 'Nuestros aliados en asuntos globales.',
        text: 'Nuestro objetivo es asociarnos con entidades que compartan nuestros valores. Por lo tanto, Frontiers Gold & Gems es miembro de la Camara de Comercio Árabe Brasilera.'
      }
    }
  },
  about:{
    title: 'Quienes Somos',
    services:{
      title: 'Nuestros servicios.',
      text:'<ul><li>Evaluación.</li><li>Aprendizaje.</li><li>Planificación.</li><li>Corte y pulido de diamantes y piedras preciosas.</li><li>Fabricación de Joyas</li></ul>'
    },
    global:{
      title: 'Presencia global.',
      text:'FGGT ha construido una relación sólida con proveedores y socios y ha demostrado el conocimiento y la experiencia para ejecutar y desempeñarse en todo el mundo de metales preciosos, joyería, diamantes y piedras preciosas.'
    },
    supliers:'PARA PROVEEDORES',
  },
  preciousMetals:{
    title: 'Metales preciosos',
    sourcing:{
      title: 'Suministro y Comercio.',
      text:'La experiencia de FGGT en Comercio y Suministro de Metales Preciosos hace de FGGT una Compañía con Excelencia en la aplicación de Estándares Locales e Internacionales en Políticas y Procedimientos de Cumplimiento y Cadena de Suministro, exportando e importando en Mercados Internacionales como Sudamérica, África, Europa y el Lejano Oriente. Nuestros servicios de comercio en el mercado internacional y nuestra seguridad hacen de FGGT un líder único en lo que hacemos manteniendo relaciones a largo plazo con exportadores de oro y distribuidores de metales preciosos, mineros y comerciantes con licencia, ubicados en todo el mundo para obtener y adquirir metales preciosos directamente de proveedores locales y Mineros.'
    },
    refining:{
      title:'Refinamiento.',
      text:'FGGT es una empresa de metales preciosos integrada e innovadora que promueve la Industria del oro, platino y plata mediante el desarrollo y el fomento de los mercados de productos de oro, platino y plata en todo el mundo, especialmente Barras Dore.',
    },
    doreBars: {
      title:'Barras de Dore.',
      text:'Con nuestra extensa red global de subsidiarias de abastecimiento, complementada por nuestros proveedores confiables con quienes hemos construido asociaciones sólidas y gratificantes; nosotros, obtenemos barras de oro, platino y plata Dore directamente de las minas mismas, ofreciendo a nuestros clientes una calidad, valor y consistencia incomparables en productos y servicios.'
    },
    selling:{
      title:'Venta de oro, platino y / o plata en Dubai.',
      text:'Si desea vender oro, necesita un socio en quien pueda confiar. La venta de oro es simplemente una cuestión de confianza FGGT garantiza la transparencia a la hora de comprar su oro. <br/>Compramos todos los artículos de Oro, Platino y / o Plata. Por ejemplo:<br/><ul> <li>Joyas viejas o rotas (joyas ancestrales, oro de desecho, oro roto, joyas de oro y plata): anillos, cadenas, aretes, pulseras y mucho más.</li> <li>Joyas de diamantes, joyas antiguas, joyas con piedras o similares, joyas con cierre.</li><li>Cubiertos y cubiertos de plata (incl. Cubiertos de plata).</li><li>Oro dental.</li><li>Monedas de oro y plata (por ejemplo, ducados, chelines).</li><li>Lingotes de oro y plata de todos los fabricantes.</li><li></li>Relojes de lujo, también de acero.<li>Relojes de oro y relojes de plata (también oro roto).</li><li>Diamantes.</li></ul>'
    }
  },
  diamonds:{
    title: 'Diamantes',
    certified:{
      title:'Diamantes sueltos certificados.',
      text:'FGGT utiliza prácticas de fabricación de vanguardia, asegurando el cumplimiento de los más altos estándares de salud y seguridad, mientras gestiona la creación de valor desde diamantes en bruto hasta diamantes pulidos. Con la incorporación de tecnologías de punta en nuestros procedimientos junto con personal altamente experimentado, transformando cada diamante en bruto en uno brillante. Nuestro meticuloso proceso de fabricación de diamantes en todos nuestros socios implica los siguientes procesos: planificación, clasificación, clasificación, corte, pulido y certificación.'
    },
    fancy:{
      title:'Diamantes de lujo.',
      text:'Spark and Shine of a Diamond in Colors, la variedad más exclusiva de Fancy Diamonds para dar a FGGT el valor agregado para nuestros Clientes.'
    },
    rough:{
      title:'Diamantes en bruto.',
      text:'FGGT es líder en la obtención de diamantes en bruto sostenibles y compatibles (sin conflictos, con certificación del proceso de Kimberley) de minas de calidad y proveedores de los principales países productores de diamantes. Además de nuestra amplia red existente de proveedores, nuevos proveedores continúan uniéndose a nuestra red de socios, lo que nos permite diversificar nuestra cartera y les permite respaldar sus objetivos.'
    },
    selling:{
      title:'Venta de diamantes en Dubai.',
      text:'Al igual que los metales preciosos, los diamantes también pueden actuar como su activo precioso, para monetizar sus diamantes, puede venderlos al igual que el oro, platino y plata, pero el obstáculo que surge es encontrar un comprador auténtico, FGGT es su comprador confiable.'
    }
  },
  gemstones:{
    title:'Piedras Preciosas',
    info:'FGGT es conocida por obtener piedras preciosas de calidad excepcional y auténtica, que muestran un brillo único y natural y están destinadas a realzar el deseo de nuestros clientes.',
    emeralds:{
      title:'Esmeraldas.',
      text:'Como una de las Piedras Preciosas más deseadas, nuestro interés en Importar y Exportar Esmeraldas en bruto y pulidas y la alta demanda en el mercado hicieron de FGGT su Socio de la Cadena de Suministro para asegurar la mejor calidad de Esmeraldas.'
    },
    selling:{
      title:'Venta de piedras preciosas en Dubai.',
      text:'Al igual que los metales preciosos, las piedras preciosas también pueden actuar como su activo precioso, para monetarizar sus piedras preciosas, puede venderlas al igual que los diamantes, pero el obstáculo que surge es encontrar un comprador auténtico, FGGT es su comprador de confianza.'
    }
  },
  compliance:{
    title: 'Cumplimiento',
    diligence: {
      title: 'Debida Diligencia.',
      text:'FGGT demuestra la aplicación y el cumplimiento de los estándares de seguridad de la cadena de suministro locales e internacionales, donde los compradores de metales y piedras preciosos deben evaluar continuamente su cadena de suministro para identificar, mitigar y eliminar posibles riesgos de seguridad y monitorear la logística del producto.'
    },
    policies:{
      title:'Políticas y Procedimientos.',
      text:`FGGT asegura el cumplimiento de los estrictos estándares y políticas de cumplimiento que deben cumplir y ser aplicados por proveedores, inversionistas 
      externos, clientes y asociados para participar en cualquiera de las actividades comerciales con la empresa, que son las siguientes:  <br/><br/>
      <div className="list"><ul>
      <li>POLÍTICA DE DEBIDA DILIGENCIA DE LA CADENA DE SUMINISTRO FGGT 2025. <a href="./assets/documents/SUPPLY CHAIN DUE DILIGENCE POLICY FGGT 2025.pdf" target="_blank">Ver documentación aquí.</a></li>
      <li>MANUAL ALD PARA POLÍTICAS Y PROCEDIMIENTOS FGGT 2025. <a href="./assets/documents/AML MANUAL FOR POLICIES & PROCEDURES FGGT 2025.pdf" target="_blank">Ver documentación aquí.</a></li>
      <li> CUMPLIMIENTO DE LA POLÍTICA ALD Y PROCEDIMIENTOS DE SUPERVISIÓN FGGT 2025.<a href="./assets/documents/AML POLICY COMPLIANCE & SUPERVISORY PROCEDURES FGGT 2025.pdf" target="_blank">Ver documentación aquí.</a></li>
      <li>ESTÁNDAR DE ORO LIBRE DE CONFLICTOS METALES PRECIOSOS FUENTE FGGT 2025.  <a href="./assets/documents/Conflict free gold standard, WDC SoW adherence & grievance mechanism 2025.pdf" target="_blank">Ver documentación aquí.</a></li>
      <li>FRONTIERS GOLD & GEMS TRADING L.L.C. DATA PRIVACY POLICY 2025. <a href="./assets/documents/Data Privacy Policy FGGT 2025 .pdf" target="_blank">Ver documentación aquí.</a></li>
      <li>FRONTIERS GOLD & GEMS TRADING L.L.C. FCPA & ANTI-BRIBERY POLICY 2025. <a href="./assets/documents/FCPA & Anti-Bribery Policy FGGT 2025.pdf" target="_blank">Ver documentación aquí.</a></li>
      </ul></div>`
    },
    international:{
      title:'Metales Preciosos Internacionales - Diamantes - Políticas y Procedimientos de Piedras Preciosas para el comercio y el suministro.',
      text:'FGGT no solo se adhiere a sus propios estándares y políticas; pero también se adhiere y asegura que todos los negocios se lleven a cabo de acuerdo con los estándares y políticas establecidas por los órganos de gobierno internacionales y globales, que son los siguientes:'
    }

  },
  jewerly:{
    title:'Joyería',
    design:{
      'title': 'Ingrese al mundo de la moda y el diseño para su propia colección de joyas con FGGT',
      'text':'Las joyas pueden estar hechas de una amplia gama de materiales. Los diamantes y piedras preciosas y materiales similares como el ámbar y el coral, los metales preciosos, las cuentas y las conchas se han utilizado ampliamente, y el esmalte a menudo ha sido importante. En la mayoría de las culturas, la joyería puede entenderse como un símbolo de estatus, por sus propiedades materiales, sus patrones o símbolos significativos. Se han elaborado joyas para adornar casi todas las partes del cuerpo, desde horquillas hasta anillos en los dedos de los pies.'
    },
    hight:{
      title:'Alta Joyería',
      text:'La pasión de FGGT ha animado sus creaciones de Alta Joyería. FGGT comparte su visión única del oficio, marcada por fuentes encantadoras de inspiración y conocimientos virtuosos. Cada piedra meticulosamente seleccionada se elige por su calidad excepcional y la emoción que transmite.<br/><br/><span>En FGGT podemos crear la gran pieza de joyería de sus sueños con lo único y único de su tipo.</span>'
    }
  },
  contact: {
    title: 'Contáctenos',
    form: {
      name:'Nombre',
      surname:'Apellido',
      email:'E-mail',
      phone: 'Número De Teléfono',
      cancel:'Cancelar',
      send:'Enviar',
      carrers:'Carreras'
    }
  },
  footer: {
    call: 'LLAMANOS',
    write:'ESCRIBENOS',
    visit: 'VISITANOS',
    business: 'HORARIO',
    open:'Domingo - Jueves',
    closeDays: 'Viernes and Sábado',
    close:'Cerrado',
    navigation:'NAVEGACIÓN'

  },
  callToAction: {
    title: 'Conviértase en proveedor de FGGT.',
    metals: 'Proveedor de Metales preciosos',
    diamonds: 'Diamantes y piedras preciosas.',
    newClient:'Regístrese como cliente.',
    button:'Aplica ya'
  },
  logistic: {
    title: 'Logística',
    text: 'En FGGT estamos comprometidos a mantener los estándares de entrega insuperables. Sea; Envíos diarios, semanales, mensuales o bajo demanda de metales preciosos, diamantes y piedras preciosas que realizamos las 24 horas del día gracias a nuestras alianzas estratégicas con los principales proveedores de logística. Todas las operaciones logísticas de FGGT se llevan a cabo de acuerdo con los requisitos y políticas internacionales, y los cumplimos estrictamente.<br/> <br/>Las asociaciones de FGGT con los principales proveedores de logística nos ayudan a agilizar todos los envíos, lo que aumenta la eficiencia de nuestros procesos de importación-exportación cumpliendo con las autoridades locales e internacionales.',
    transport: ' Estamos trabajando mano a mano con las empresas <br/>de transporte de seguridad de mejor reputación'
  },
  nanoMetals: {
    title: 'Nano Metales',
    nannoCopper: {
      title: 'Nano Copper.',
      text: 'New Frontiers Gold & Gems marca una nueva era de nanotecnología al asociarse con Minera Monte Alto, principal proveedor de nanocobre 100% orgánico*.'
    },
    process: {
      title: 'Poceso de obtencion de Particulas de cobre',
      text: '<ol><li><b>Obtencion del mineral de cobre</b><br/>Se obtiene a través de explotación subterránea el mineral de cobre en estado de Óxidos y Sulfuros.</li><br/> <li><b>Procesamiento del mineral de cobre</b><br/>El mineral es recepcionado en Planta de procesos y clasificado según su pureza, utilizando el mejor mineral para el proceso de obtención de Nano cobre. El mineral de descarte es procesado para la obtención de concentrado de cobre oro y polimetálicos. El material clasificado es triturado y seleccionado a tamaño de 6mm.</li><br/><li><b>Procesos de Bio-Lixiviacion</b><br/>El mineral es depositado en las columnas de lixiviación. Se cargan las columnas con la solución que contiene las bacterias que, a través de un proceso orgánico y flujo continuo de 10 días, dejan la solución cargada de cobre.Se efectúa la descarga de la solución, pasando a través de filtros, canalizada y depositada en las celdas de electro-obtención.</li><br/><li><b>Procesos de Electro-Obtencion</b><br/>A través de energía longitudinal, los cátodos son cargados lentamente con nanopartículas de cobre. Una vez que el cátodo está cargado iónicamente, se efectúa el proceso inverso de descarga, donde se lleva a la sección de control de calidad y clasificación.</li><br/></li><br/><li><b>Control de calidad y empaquetado</b><br>Una vez pasado el control de calidad con microscopia y clasificación nanométrica, pasa a la sección de envasado, sellado y etiquetado.</li></ol>',
      titleImage1: 'Copper Mineral',
      titleImage2: 'Bio-Leaching',
      titleImage3: 'Electro-Generation',
      titleImage4: 'Quality Control / Packaging', 
    },
    table:[
      {
        physical: 'Regular Nano Cu spherical particles with sizes from 20nm to 100nm (Other formats upon request).',
        chemical: 'Stable at room temperature in closed containers under normal storage and handling conditions.',
        visual: 'Powder, red to brown in color.',
        practical:'Advanced medications and supplements, surgical instruments and posthesis.'
      },
      {
        physical: 'Metallic Cu.',
        chemical: 'Long shelf life, more than 24 months.',
        visual: 'Dispersion in oily organic media: terpineol, ethylene glycol, Vaseline, ethanol, methanol and others according to customer needs.',
        practical:'Heat sinks in materias or thermal conductors.'
      },
      {
        physical: 'Non-oxidative.',
        chemical: 'Non-radioactive.',
        visual: '500 mg sealed strainless-steel container.',
        practical:'Electric Superconductors, construction materials.'
      },
      {
        physical: 'Environmentally friendly production.',
        chemical: 'Highly refined without contaminating traces.',
        visual: '',
        practical:'Welding material for metals and high-resistance alloys.',
      },
      {
        physical: 'Cu of "HG" specifications (cathode grade A) with purity greater than 99.98%.',
        chemical: '',
        visual: '',
        practical:'Shielding against electromagnetic interference (EMI).'
      },
      {
        physical: '100% organic.',
        chemical: '',
        visual: '',
        practical:'Effective catalyst for chemical reactions and for the synthesis of methanol and glycol.'
      },
      {
        physical: 'Purity: 99,985 %',
        chemical: '',
        visual: '',
        practical:''
      }
    ],
    tableDisclaimer: '*Includes wellbeing, medical, cosmetics, naval, pharmaceutical, construction, chemical, energy, electronics and defense',
    important: '*Todos los procedimientos comerciales relacionados con el cobre nano se realizan bajo la licencia de nuestra empresa hermana y parte de New Frontiers World Group, Loadstar General Trading LLC.',
    titleLaboratories:'OUR PRODUCT HAS BEEN CERTIFIED BY PRESTIGIOUS INTERNATIONAL LABORATORIES:'
  }
}