import React from "react";

const TableInfo = () => {

  return (
    <>
      <table className="table_container">
        <thead>
          <tr>
            <th>Physical</th>
            <th>Chemical</th>
            <th>Visual</th>
            <th>Practical*</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Regular Nano Cu spherical particles with sizes from 20nm to 100nm
              (Other formats upon request).{" "}
            </td>
            <td>
              Stable at room temperature in closed containers under normal storage
              and handling conditions.
            </td>
            <td>Powder, red to brown in color.</td>
            <td>
              Advanced medications and supplements, surgical instruments and
              prosthesis.
            </td>
          </tr>
          <tr>
            <td>Metallic Cu.</td>
            <td>Long shelf life, more than 24 months.</td>
            <td>
              Dispersion in oily organic media: terpineol, ethylene glycol,
              Vaseline, ethanol, methanol and others according to customer needs.
            </td>
            <td>Heat sinks in materials or thermal conductors.</td>
          </tr>
          <tr>
            <td>Non-oxidative.</td>
            <td>Non-radioactive.</td>
            <td>500 mg sealed stainless-steel container.</td>
            <td>Electric Superconductors, construction materials.</td>
          </tr>
          <tr>
            <td>Environmentally friendly production.</td>
            <td>Highly refined without contaminating traces.</td>
            <td></td>
            <td>Welding material for metals and high-resistance alloys.</td>
          </tr>
          <tr>
            <td>
              Cu of "HG" specifications (cathode grade A) with purity greater than
              99.98%.
            </td>
            <td></td>
            <td></td>
            <td>Shielding against electromagnetic interference (EMI).</td>
          </tr>
          <tr>
            <td>100% organic.</td>
            <td></td>
            <td></td>
            <td>
              Effective catalyst for chemical reactions and for the synthesis of
              methanol and glycol.
            </td>
          </tr>
          <tr>
            <td>Purity: 99,985 %</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div className="table_mobile">
        <h4>Physical</h4>
        <ul>
          <li>Regular Nano Cu spherical particles with sizes from 20nm to 100nm (Other formats upon request).</li>
          <li>Metallic Cu.</li>
          <li>Non-oxidative.</li>
          <li>Environmentally friendly production.</li>
          <li>Cu of "HG" specifications (cathode grade A) with purity greater than 99.98%.</li>
          <li>100% organic.</li>
          <li>Purity: 99,985 %</li>
        </ul>

        <h4>Chemical</h4>
        <ul>
          <li>Stable at room temperature in closed containers under normal storage and handling conditions.</li>
          <li>Long shelf life, more than 24 months.</li>
          <li>Non-radioactive.</li>
          <li>Highly refined without contaminating traces.'.</li>
        </ul>

        <h4>Visual</h4>
        <ul>
          <li>Powder, red to brown in color.</li>
          <li>Dispersion in oily organic media: terpineol, ethylene glycol, Vaseline, ethanol, methanol and others according to customer needs.</li>
          <li>500 mg sealed strainless-steel container.</li>
        </ul>
        <h4>Practical</h4>
        <h4>(Includes wellbeing, medical, cosmetics, naval, pharmaceutical, construction, chemical, energy, electronics and defense).</h4>
        <ul>
          <li>Advanced medications and supplements, surgical instruments and posthesis.</li>
          <li>Heat sinks in materias or thermal conductors.</li>
          <li>Electric Superconductors, construction materials.</li>
          <li>Welding material for metals and high-resistance alloys.</li>
          <li>Shielding against electromagnetic interference (EMI).</li>
          <li>Effective catalyst for chemical reactions and for the synthesis of methanol and glycol.</li>
        </ul>
      </div>
    </>
  );
};

export default TableInfo;
