import React from 'react'
import {ListStyle} from '../../../style/utils/list/style.js'
import parse from 'html-react-parser'
import {container} from '../../../style/var'
//lang
import { useTranslation } from 'react-i18next'

const List = ({data}) => {
  const { t } = useTranslation()
  return (
    <div className={ListStyle}>
      <div className={`${container} list`}>
        {data.map((item,i)=>
        <div className="info" key ={i} >
          <div className="line">
            <svg height="8" width="122">
              <line x1="0" y1="0" x2="122" y2="0" style={{stroke:'#333333',strokeWidth:2}} />
            </svg>
          </div>
          <div className="text">
            <div className="infoTitle">{t(item.title)}</div>
            <div className="infoDescription">
              {parse(t(item.text))}
            </div>
            <div className="galery">
              {item.image1 &&
              <div className="item"  style= {{ width: `${item.width}`}}>
                <img src={item.image1} alt={item.title} width="100%" className="desktop"/>
                {item.image1mobile && 
                  <img src={item.image1mobile} alt={item.title} width="100%" className="mobile"/>
                }
                {item.titleImage1 &&
                  <p>{t(item.titleImage1)}</p>
                }
              </div>
              }
              {
                item.image2 &&
              <div className="item" style= {{ width: `${item.width2}`}}>
                <img src={item.image2} alt={item.title} width="100%" className="desktop"/>
                {item.image2mobile &&
                  <img src={item.image2mobile} alt={item.title} width="100%" className="mobile"/>
                }
                {item.titleImage2 &&
                  <p>{t(item.titleImage2)}</p>
                  }
              </div>
              }
              {item.image3 &&
                <div className="item" style= {{ width: `${item.width3}`}}>
                  <img src={item.image3} alt={item.title} width="100%" className="desktop"/>
                  {item.image3mobile &&
                    <img src={item.image3mobile} alt={item.title} width="100%" className="mobile"/>
                  }
                  {item.titleImage3 &&
                    <p>{t(item.titleImage3)}</p>
                    }
                </div>
              }
              {
                item.image4 &&
              <div className="item" style= {{ width: `${item.width4}`}}>
                <img src={item.image4} alt={item.title} width="100%" className="desktop"/>
                {item.image4mobile &&
                  <img src={item.image4mobile} alt={item.title} width="100%" className="mobile"/>
                }
                {item.titleImage4 &&
                  <p>{t(item.titleImage4)}</p>
                  }
              </div>
              }
            </div>

          </div>
        </div>
        )}
      </div>
    </div>
  )
}

export default List
