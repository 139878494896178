import { style } from "typestyle";
import { Color, Font } from "../var";

export const NanoMetalsStyle = style({
  width: "100%",
  fontFamily: "Avenir",
  $nest: {
    "& .tableInfo": {
      display: "flex",
      marginBottom: "100px",
      $nest: {
        "& .line": {
          marginTop: "10px",
          $nest: {
            "@media screen and (max-width: 700px)": {
              display: "none",
            },
          },
        },
        "& .text": {
          marginLeft: "16px",
          width: "90%",
          color: Color.black,
          $nest: {
            "& .infoTitle": {
              fontSize: Font.medium,
              fontWeight: "bold",
              color: Color.black,
              marginBottom: '20px',
            },
            "& table, th,td": {
              border: `2px solid ${Color.darkGold}`,
              borderCollapse: "collapse",
              padding: "10px",
              boxSizing: "border-box",
              color: Color.black,
              borderRadius: '40px'
            },
            '& .important_text': {
              fontWeight: 'bold',
              fontSize: Font.principal,
              color: Color.mediumGray,
              marginTop: '60px',
            },
            "& .galeryProducts":{
              marginTop: "100px",
              marginBottom: "100px",
              width: "100%",
              $nest: {
                '& .galeryTitle':{
                  color: Color.lightGold,
                  fontWeight: 'bold',
                  fontSize: '18px'
                },
                '& .galeryContainers':{
                  marginTop: "58px",
                  display: 'flex',
                  alignItems: 'center',
                  gap: '40px',
                  $nest: {
                    '& .item':{
                      width: "320px",
                      $nest: {
                        '& img':{
                          width: "100%"
                        }
                      }
                    }
                  }
                }
              }
            },
            '& .table_mobile':{
              display: "none"
            }
          },
        },
      },
    },
    '@media screen and (max-width: 700px)':{
      width: "100%",
      $nest:{
        "& .tableInfo":{
          flexDirection: 'column',
          margin: '0px auto',
          $nest:{
            '& .text':{
              marginLeft: "0px",
              $nest:{
                '& table':{
                  display: "none"
                },
                '& .table_mobile':{
                  display: "block",
                  $nest: {
                    '& h4':{
                      color: Color.darkGold,
                    },
                    '& li':{
                      color: Color.black,
                    }
                  }
                },
                '& .disclaimer':{
                  display: 'none',
                },
                "& .galeryProducts":{
                  marginTop: "50px",
                  marginBottom: "50px",
                  $nest: {
                    '& .galeryContainers':{
                      flexDirection: 'column',
                      $nest: {
                        '& .item': {
                          width: "100%"
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
});
