import {style} from 'typestyle'
import {Color, Font} from '../var'

export const HomeStyle = style({
  width: '100%',
  fontFamily:'Avenir',
  paddingTop:'140px',
  $nest: {
   '& .title': {
      height: '340px',
      backgroundImage: 'linear-gradient(to right, #c6af6b 51%, #635836)',
      width: '100%',
      marginTop: '-20px',
      $nest: {
        '& .text': {
          height: '100%',
          width: '70%',
          display: 'flex',
          alignItems: 'center',
          color: Color.white,
          fontSize: Font.Big,
          lineHeight: '1.25',
          fontWeight: 'normal',
          $nest: {
            '@media screen and (max-width: 1440px)':{
              width: '100%',
              $nest: {
                '@media screen and (max-width: 1440px)':{
                  fontSize: Font.minBig
                },
              }
            },
            '@media screen and (max-width: 700px)':{
              fontSize: Font.intermedium
            }
          }
        },
        '@media screen and (max-width: 1440px)':{
          height: '300px',
        }
      }
   },
   '& .company': {
     display: 'flex',
     flexDirection: 'column',
     margin: '100px 0',
     $nest: {
       '& .titleCompany':{
          color: Color.lightGold,
          fontSize:Font.MegaBig,
          textTransform: 'uppercase',
          $nest:{
            '@media screen and (max-width: 700px)':{
              fontSize:Font.intermedium,
            }
          }
       },
       '& .info':{
         width: '100%',
         display: 'flex',
         marginTop: '29px',
         marginBottom: '20px',
         $nest: {
           '& .line': {
            marginTop: '10px',
            $nest: {
              '@media screen and (max-width: 700px)':{
                display: 'none'
              }
            }
           },
          '& .text': {
            marginLeft:'16px',
            width: '70%',
            $nest: {
              '& .infoTitle': {
                fontSize: Font.medium,
                fontWeight: 'bold',
                color: Color.black,
                $nest:{
                  '@media screen and (max-width: 700px)':{
                    fontSize: Font.principal
                  }
                }
              },
              '& .infoDescription': {
                marginTop: '34px',
                fontSize: Font.principal,
                color: Color.mediumGray,
                $nest:{
                  '@media screen and (max-width: 700px)':{
                    fontSize: Font.normal,
                    fontWeight: '100'
                  }
                }
              },
              '& .alliesImages': {
                display: 'flex',
                alignItems: 'flex-start',
                gap: '30px'
              },
              '& .infoImagen':{
                width:'200px',
                marginTop: '30px',
                marginLeft: '-20px',
                $nest: {
                  '@media screen and (max-width: 700px)':{
                    width:'100px',
                    marginLeft: '-10px'
                  },
                },
              },
              '@media screen and (max-width: 700px)':{
                marginLeft:'0px',
                width: '100%',
              }
            }
          }
         }
       }, 
       '@media screen and (max-width: 700px)':{
          margin: '60px 0',
       }
     }
   },
   '& .desktop':{
     display:'flex',
     width:'100%',
     $nest: {
       '@media screen and (max-width: 700px)':{
         display: 'none'
       }
     }
   },
   '& .mobile':{
     display: 'none',
     $nest: {
       '@media screen and (max-width: 700px)':{
         display:'flex',
         width: '100%'
       }
     }
   },
   '@media screen and (max-width: 834px)':{
    paddingTop:'0px',
   }
  }
})