import {style} from 'typestyle'
import {Font, Color} from '../../var'

export const ListStyle = style({
  width: '100%',
  fontFamily:'Avenir',
  display: 'flex',
  flexDirection: 'column',
  $nest: {
    '& .list': {
      marginTop: '40px',
      marginBottom: '60px',
      $nest:{
        '& .info':{
          width: '100%',
          display: 'flex',
          marginTop: '60px',
          $nest: {
            '& .line': {
             marginTop: '10px',
             $nest:{
               '@media screen and (max-width: 700px)':{
                 display:'none',
               }
             }
            },
           '& .text': {
             marginLeft:'16px',
             width: '90%',
             $nest: {
               '& .infoTitle': {
                 fontSize: Font.medium,
                 fontWeight: 'bold',
                 color: Color.black,
                 $nest:{
                   '@media screen and (max-width: 700px)':{
                    fontSize: Font.principal,
                    margin: '0 37px'
                   }
                 }
               },
               '& .infoDescription': {
                 marginTop: '34px',
                 fontSize: Font.principal,
                 color: Color.mediumGray,
                 width: '73%',
                 $nest:{
                  '@media screen and (max-width: 700px)':{
                   fontSize: Font.normal,
                   width: '100%',
                   padding: '0 37px',
                   boxSizing:'border-box'
                  }
                }
               },
               '& .galery': {
                 display: 'flex',
                 width: '100%',
                 marginTop: '57px',
                 $nest: {
                   '& .item':{
                     marginRight:'3px',
                     $nest: {
                       '& p':{
                        width: '100%',
                        textAlign: 'center',
                        margin:'0',
                        color: Color.lightGold,
                        fontWeight: '600'
                       },
                       '@media screen and (max-width: 700px)':{
                         marginRight:'0px'
                       }
                     }
                   },
                   '& img':{
                     width: '100%'
                   },
                   '& .desktop':{
                     display: 'flex',
                     $nest:{
                      '@media screen and (max-width: 700px)':{
                        display: 'none'
                      }
                     }
                   },
                   '& .mobile':{
                    display: 'none',
                    $nest:{
                     '@media screen and (max-width: 700px)':{
                       display: 'flex'
                     }
                    }
                  },
                  '@media screen and (max-width: 700px)':{
                    flexDirection: 'column'
                  }
                 }
               },
               '@media screen and (max-width: 700px)':{
                 width: '100%',
                 marginLeft:'0px',
               }
             }
           },
           '@media screen and (max-width: 700px)':{
            marginTop: '60px',
            marginBottom: '60px',
            $nest:{
              '& .item':{
                width: '100% !important',
                marginBottom: '2px',
                $nest:{
                  '@media screen and (max-width: 700px)':{
                    '& p':{
                      display: 'none'
                    }
                  }
                }
              }
            }
           }
          }
        },
      }
    },
    '@media screen and (max-width: 700px)':{
      '& .list':{
        padding:'0',
        marginBottom: '0px',
        marginTop: '0px',
      }
    }
  }
})