import React, { useContext } from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import parse from "html-react-parser";
//Component
import Galery from "../utils/galery";
//Context
import { HomeContext } from "../../context/home";

//Style
import { HomeStyle } from "../../style/home/style";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { container } from "../../style/var";
//Lang
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  SwiperCore.use([
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    EffectFade,
    Autoplay,
  ]);
  const { company, galery } = useContext(HomeContext);

  return (
    <div className={HomeStyle}>
      <Swiper spaceBetween={0} slidesPerView={1} autoplay={{ delay: 3000 }}>
        <SwiperSlide data-swiper-autoplay="3000">
          <img
            src="./assets/Desktop/Home/es-fggt-home-slide1.png"
            alt="imageBanner1"
            className="desktop"
          />
          <img
            src="./assets/Mobile/Home/mo-fggt-home-slide1.png"
            alt="imageBanner1"
            className="mobile"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="./assets/Desktop/Home/es-fggt-home-slide2.png"
            alt="imageBanner1"
            className="desktop"
          />
          <img
            src="./assets/Mobile/Home/mo-fggt-home-slide2.png"
            alt="imageBanner1"
            className="mobile"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="./assets/Desktop/Home/es-fggt-home-slide3.png"
            alt="imageBanner1"
            className="desktop"
          />
          <img
            src="./assets/Mobile/Home/mo-fggt-home-slide3.png"
            alt="imageBanner1"
            className="mobile"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="./assets/Desktop/Home/es-fggt-home-slide4.png"
            alt="imageBanner1"
            className="desktop"
          />
          <img
            src="./assets/Mobile/Home/mo-fggt-home-slide4.png"
            alt="imageBanner1"
            className="mobile"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="./assets/Desktop/Home/es-fggt-home-slide5.png"
            alt="imageBanner1"
            className="desktop"
          />
          <img
            src="./assets/Mobile/Home/mo-fggt-home-slide5.png"
            alt="imageBanner1"
            className="mobile"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="./assets/Desktop/Home/es-fggt-home-slide6.png"
            alt="imageBanner1"
            className="desktop"
          />
          <img
            src="./assets/Mobile/Home/mo-fggt-home-slide6.png"
            alt="imageBanner1"
            className="mobile"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="./assets/Desktop/Home/es-fggt-home-slide7.png"
            alt="imageBanner1"
            className="desktop"
          />
          <img
            src="./assets/Mobile/Home/mo-fggt-home-slide7.png"
            alt="imageBanner1"
            className="mobile"
          />
        </SwiperSlide>
      </Swiper>
      <div className="title">
        <div className={`${container} text`}>{parse(t("home.banner"))}</div>
      </div>
      <Galery data={galery} />
      <div className={`${container} company`}>
        <div className="titleCompany">{t("home.company.title")}</div>
        {company.map((item, i) => (
          <div className="info" key={i}>
            <div className="line">
              <svg height="8" width="122">
                <line
                  x1="0"
                  y1="0"
                  x2="122"
                  y2="0"
                  style={{ stroke: "#333333", strokeWidth: 2 }}
                />
              </svg>
            </div>
            <div className="text">
              <div className="infoTitle"> {parse(t(item.title))}</div>
              <div className="infoDescription">{parse(t(item.text))}</div>
            </div>
          </div>
        ))}
          <div className="info">
            <div className="line">
              <svg height="8" width="122">
                <line
                  x1="0"
                  y1="0"
                  x2="122"
                  y2="0"
                  style={{ stroke: "#333333", strokeWidth: 2 }}
                />
              </svg>
            </div>
            <div className="text">
              <div className="infoTitle"> {t("home.company.allies.title")}</div>
              <div className="infoDescription">
                {t("home.company.allies.text")}
              </div>
              <div className="alliesImages">
              <img
                src="./assets/Desktop/Home/SealMember.jpeg"
                alt="imageBanner1"
                className="infoImagen"
              />
              <img
                src="./assets/Desktop/Home/dubaiGold.png"
                alt="imageBanner2"
                className="infoImagen"
              />
              </div>
            </div>
          </div>

      </div>
    </div>
  );
};

export default Home;
