import React, { useContext } from 'react'
import Banner from '../utils/banner'
import parse from 'html-react-parser'
//Context
import{JewelryContext} from '../../context/jewelry'
//Style
import {container} from '../../style/var'
import {JewerlyStyle} from '../../style/jewerly/style'

import {useTranslation} from 'react-i18next'

const Jewerly = () => {
  const {t} = useTranslation()
  const{
    list
  } = useContext(JewelryContext)
  return (
    <div className={JewerlyStyle}>
      <Banner
        img ='./assets/Desktop/Jewelry/es-fggt-jewelry-img1.png'
        imgResponsive= './assets/Mobile/Jewelry/mo-fggt-jewelry-img1..png'
         title = 'jewerly.title'
      />

      <div className={`${container} jewerly`}>
        {list.map((item,i)=>
        <div className="info" key={i}>
          <div className="line">
            <svg height="8" width="122">
              <line x1="0" y1="0" x2="122" y2="0" style={{stroke:'#333333',strokeWidth:2}} />
            </svg>
          </div>
          <div className="text">
            <div className="infoTitle">{t(item.title)}</div>
            <div className="infoDescription">
              {parse(t(item.text))}
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  )
}
export default Jewerly